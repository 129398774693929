import Alpine from "alpinejs";
window.Alpine = Alpine;
Alpine.start();

import "mburger-webcomponent";

import tippy from "tippy.js";
window.tippy = tippy;
tippy("[data-tippy-content]", {
  allowHTML: true,
  hideOnClick: false,
});

import GLightbox from "glightbox"; //Lightbox
const lightbox = GLightbox({
  touchNavigation: true,
  selector: "data-lightbox",
  videosWidth: "80vw",
});

// import Swiper bundle with all modules installed
import Swiper from "swiper/bundle";

const swiper = new Swiper(".swiper-home", {
  loop: true,
  autoplay: {
    delay: 3000,
  },
  speed: 1000,
  navigation: {
    nextEl: ".swiper-button-next",
    prevEl: ".swiper-button-prev",
  },
});

const swiperListingThumbs = new Swiper(".swiper-listing-thumbs", {
  spaceBetween: 10,
  slidesPerView: 3,
  watchSlidesProgress: true,
  breakpoints: {
    320: {
      slidesPerView: 3,
    },
    640: {
      slidesPerView: 5,
    },
    1000: {
      slidesPerView: 7,
    },
  },
  navigation: {
    nextEl: ".swiper-button-next",
    prevEl: ".swiper-button-prev",
  },
});

const swiperListing = new Swiper(".swiper-listing", {
  spaceBetween: 10,
  navigation: {
    nextEl: ".swiper-button-next",
    prevEl: ".swiper-button-prev",
  },
  thumbs: {
    swiper: swiperListingThumbs,
  },
});

import "mmenu-js";
